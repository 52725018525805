<template>
	<div class="card__empty">
		<p>
			{{ text }}
		</p>
	</div>
</template>

<script>
export default {
	name: 'EmptyCard',

	props: {
		text: {
			type: String,
			required: true
		}
	}

};

</script>

<style lang="scss" scoped>
@import "@/scss/_colors.scss";

.card__empty {
    height: 350px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $gray-300;

    > p {
      font-size: 1.25rem !important;
      padding: 0px 10%;
      text-align: center;
      color: $gray-600;
    }
  }

</style>
