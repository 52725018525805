<template>

	<v-card class="card mx-auto" outlined tile flat>

		<v-skeleton-loader
			type="card"
			height="350"
			v-if="loading"
		></v-skeleton-loader>

		<template v-else>

			<v-img
				v-if="file.extension === 'PDF'"
				position="top center"
				lazy-src="https://picsum.photos/id/11/10/6"
				:src=" `${fileUrl}${file.thumbnailKey}`"
			></v-img>
			<v-img
				v-else-if="file.extension === 'PPTX' || file.extension === 'PPT'"
				contain
				src="@/assets/collateral-methodology/ppt.png"
			></v-img>
			<v-img
				v-else
				:src="file.src"
			></v-img>

			<div class="card__body d-flex flex-column justify-space-between">

				<div>

					<v-card-subtitle class="pb-1 caption">
						<span> {{ file.year }} </span>
						<span> {{ file.extension }} </span>
						<span v-if="file.numPages"> {{ `${file.numPages}pp`}} </span>
					</v-card-subtitle>

					<v-card-text class="body-2 card__body__description">
						<p>	{{ file.name }} </p>
					</v-card-text>

				</div>

				<v-card-actions>
					<a @click="$emit('preview', file)"
						class="black--text font-weight-normal d-flex mr-2"
						v-if="!$vuetify.breakpoint.mdAndDown && file.extension !== 'PPTX' && file.extension !== 'PPT'"
					>
						<v-icon class="black--text material-icons-round rounded-circle v-card__actions__arrow">
							add
						</v-icon>

						<span class="ml-1">
							Read
						</span>
					</a>

					<a @click="$emit('download', file)"
						class="black--text font-weight-normal d-flex"
					>
						<v-icon class="black--text material-icons-round rounded-circle v-card__actions__arrow">
							arrow_downward
						</v-icon>

						<span class="ml-1">
							Download
						</span>
					</a>
				</v-card-actions>

			</div>

		</template>

	</v-card>

</template>

<script>
export default {
	name: 'FileCard',

	props: {
		loading: {
			type: Boolean,
			default: false
		},
		file: {
			type: Object,
			default: () => ({})
		},
		fileUrl: {
			type: String,
			required: true
		}
	}
};
</script>

<style lang="scss" scoped>
@import "@/scss/_colors.scss";


.v-card__title h2 {
  font-size: clamp(1.38rem, calc(0.14rem + 1.92vw), 1.88rem);
  line-height: 1.2em;
}

.v-card .v-image {
  aspect-ratio: 15/10 !important;
}

.card__body {
  color: $gray-600;
  flex-grow: 1;

  .card__body__description p {
    margin-bottom: 0;
    height: 80px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    font-family: 'Optimistic Text', 'Noto Sans Khmer', sans-serif !important;
    font-weight: 600;
    letter-spacing: .09px;
    color: $meta_gray;
  }
}

.v-card__title h2 {
  filter: drop-shadow(0 1px 18px rgba(0,0,0,1)) drop-shadow(0 1px 2px rgba(0,0,0,0.5));
  word-break: break-word;
}

.v-card__subtitle span {
  position: relative;
  line-height: 1em;

  &~span {
    padding-left: 11px;
  }

  &:last-child::after {
    display: none;
  }

  &::after {
    content: "";
    height: 0.6em;
    width: 1px;
    position: absolute;
    right: -5px;
    top: 50%;
    margin-top: -0.3em;
    background-color: $gray-300;
  }
}

  .v-card__actions {
    padding: 16px;

    .v-card__actions__arrow {
      transform: scale(0.8);
      border: 1px solid #000
    }

  }

</style>
